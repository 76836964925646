<template>
  <div>
    <c-table
      ref="table"
      title="배출량조사표"
      tableId="table"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
export default {
  name: 'emissions-hardcoding',
  components: {
  },
  props: {
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'col0' },
          { index: 1, colName: 'id' },
          { index: 2, colName: 'id' },
          { index: 3, colName: 'id' },
          { index: 4, colName: 'id' },
          { index: 5, colName: 'id' },
        ],
        columns: [
          {
            name: 'col0',
            field: 'col0',
            description: 'description',
            label: '구분',
            align: 'center',
            style: 'width:75px',
            sortable: false,
          },
          {
            name: 'col1',
            field: 'col1',
            label: '화학물질',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'col2',
            field: 'col2',
            label: 'CAS No.',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'throughput',
            field: 'throughput',
            label: '취급량',
            align: 'center',
            child: [
              {
                name: 'col3',
                field: 'col3',
                label: '생산량(톤/년)',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'col4',
                field: 'col4',
                label: '사용량(톤/년)',
                align: 'right',
                style: 'width:50px',
                sortable: false,
                type: 'cost',
              },
            ]
          },
          {
            name: 'col5',
            field: 'col5',
            label: '조사대상범위[무게항유율(%)]',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'chemprod',
            field: 'chemprod',
            label: '사용제품',
            align: 'center',
            child: [
              {
                name: 'col6',
                field: 'col6',
                label: '화학자재',
                align: 'left',
                style: 'width:200px',
                sortable: false,
              },
              {
                name: 'col7',
                field: 'col7',
                label: '납품업체',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
              {
                name: 'col8',
                field: 'col8',
                label: '사용량(톤/년)',
                align: 'right',
                style: 'width:80px',
                sortable: false,
                type: 'cost',
              },
            ]
          },
        ],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          id: 1,
          description: '(취급량 1톤/년 이상인 경우 배출량조사 대상)',
          col0: '1그룹', 
          col1: '트리클로로에틸렌', 
          col2: '000079-01-6', 
          col3: '1.02', 
          col4: '1.45', 
          col5: '0.1이상', 
          col6: 'PARAFFINE OIL : SG 0.88MIN', 
          col7: '(주)남동기계', 
          col8: '2.24', 
        },
        {
          id: 2,
          description: '(취급량 1톤/년 이상인 경우 배출량조사 대상)',
          col0: '1그룹', 
          col1: '2,4-디니트로페놀', 
          col2: '000051-28-5', 
          col3: '2.02', 
          col4: '1.25', 
          col5: '1.0이상', 
          col6: 'IRON OXIDE : FE2O3 99.4% MIN', 
          col7: '(주)허논', 
          col8: '2.14', 
        },
        {
          id: 2,
          description: '(취급량 1톤/년 이상인 경우 배출량조사 대상)',
          col0: '1그룹', 
          col1: '2,4-디니트로페놀', 
          col2: '000051-28-5', 
          col3: '2.02', 
          col4: '1.25', 
          col5: '1.0이상', 
          col6: 'ALUMINUM STD SOLUTION : 1000PPMx100ML', 
          col7: '태종화학상사', 
          col8: '1.08', 
        },
        {
          id: 3,
          description: '(취급량 10톤/년 이상인 경우 배출량조사 대상)',
          col0: '2그룹', 
          col1: '푸르푸랄', 
          col2: '000098-01-1', 
          col3: '0.02', 
          col4: '2.2', 
          col5: '1.0이상', 
          col6: 'BORIC ACID : GR H3BO3 500G', 
          col7: '(주)국제화공약품', 
          col8: '2.24', 
        },
      ]
    },
    chipDatas(col15) {
      return this.$_.split(col15, ',');
    },
  }
};
</script>